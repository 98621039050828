import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const mergeTrackingValues = (trackingValue1, trackingValue2) => trackingValue2 ? `${trackingValue1} (${trackingValue2})` : trackingValue1;
export class UsageTracker {
  static init() {
    this.tracker = createTracker({
      events
    });
  }
  static get() {
    if (!this.tracker) {
      this.init();
    }
    return this.tracker;
  }
  static clone(tracker) {
    this.tracker = tracker.clone({
      events
    });
  }

  // DO NOT USE: only exported for testing purposes
  static set(tracker) {
    this.tracker = tracker;
  }
  static track(...args) {
    return this.get().track(...args);
  }
}
export const EVENT_NAMES = {
  UserInteraction: 'userInteraction',
  pageView: 'pageView'
};