import Routes from 'ContentUtils/Routes';
import I18n from 'I18n';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// @ts-expect-error: shared/actions not yet typed
import { openModal } from '../../shared/actions/ModalActions';
import { ContentTypesToLegacyContentTypeStrings } from '../../shared/constants/ContentTypeConstants';
import SharedModalNames from '../../shared/constants/SharedModalNames';
import { setCreatePageModalContentType } from '../../shared/reducers/contentCreatorInfo';
import { navigateTo } from '../../shared/utils/navigationUtils';
import { useFetchPermittedDomains } from './useFetchPermittedDomains';
export const useManualPageCreation = (contentTypeId, onClick) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    permittedDomains
  } = useFetchPermittedDomains(contentTypeId);
  const onClickCreatePage = useCallback(() => {
    if (!isLoading) {
      onClick === null || onClick === void 0 || onClick();
      if (permittedDomains.length === 1) {
        navigateTo(Routes.create(contentTypeId, undefined, {
          useActiveThemeContentCreator: true,
          activeThemeContentCreatorParams: {
            domain: permittedDomains[0].domain,
            pageName: I18n.text('content-management-ui.pages.defaultPageName'),
            referrer: 'contentManager'
          }
        }));
      } else {
        const contentTypeString = ContentTypesToLegacyContentTypeStrings[contentTypeId];
        dispatch(setCreatePageModalContentType(contentTypeString));
        dispatch(openModal(SharedModalNames.CREATE_PAGE_MODAL));
      }
    }
  }, [contentTypeId, isLoading, permittedDomains, dispatch, onClick]);
  return {
    isLoading,
    onClickCreatePage
  };
};