import http from 'hub-http/clients/apiClient';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncEffect } from 'react-utils/hooks/useAsyncEffect';
import { getUserId } from '../../shared/selectors/authSelectors';
import { handleAndReportException } from '../../shared/utils/errorUtils';
export const useFetchPermittedDomains = contentTypeId => {
  const [isLoading, setIsLoading] = useState(true);
  const [permittedDomains, setPermittedDomains] = useState([]);
  const userId = useSelector(getUserId);
  useAsyncEffect(async () => {
    try {
      const response = await http.get(`cos-domains/v1/permitted-domains/for-user/${userId}`, {
        query: {
          contentTypeCategoryId: contentTypeId,
          includeBuId: true,
          isStaging: false
        }
      });
      setPermittedDomains(response);
      setIsLoading(false);
    } catch (err) {
      setPermittedDomains([]);
      setIsLoading(false);
      handleAndReportException('Unable to fetch permitted domains', err);
    }
  }, [contentTypeId, setPermittedDomains, setIsLoading, userId]);
  return {
    isLoading,
    permittedDomains
  };
};