import { useEffect, useState } from 'react';
import * as domainsClient from 'ContentData/api/Domains';
import * as templatesClient from 'ContentData/api/Templates';
// @ts-expect-error Untyped module
import { makeQueryParams } from 'ContentComponents/components/themes/TemplateList';
import { fetchPrimaryBrandKit } from 'ContentData/api/Branding';
import { getPrimaryDomainForBlog } from 'ContentData/helpers/blogCreationHelpers';
import userInfo from 'hub-http/userInfo';
const TEMPLATE_TYPE_IDS = {
  BLOG_POST: 41,
  BLOG_LISTING: 42,
  LEGACY_BLOG: 6
};
const ELEVATE_THEME_PATH = '@hubspot/elevate';
const findTemplateOfType = (templateType, templates) => templates.find(template => template.templateType === templateType);
const fetchBlogTemplates = themePath => {
  const queryParams = makeQueryParams({
    themePath,
    templateTypeIds: Object.values(TEMPLATE_TYPE_IDS)
  }, 10);
  return templatesClient.fetchTemplates(queryParams).then(({
    results: {
      objects: templates
    }
  }) => {
    const legacyBlogTemplate = findTemplateOfType(TEMPLATE_TYPE_IDS.LEGACY_BLOG, templates);
    const blogTemplate = findTemplateOfType(TEMPLATE_TYPE_IDS.BLOG_POST, templates) || legacyBlogTemplate;
    const blogListingTemplate = findTemplateOfType(TEMPLATE_TYPE_IDS.BLOG_LISTING, templates) || legacyBlogTemplate;
    if (!blogListingTemplate || !blogTemplate) {
      return Promise.reject(new Error('Required templates not found'));
    }
    return {
      blogTemplate,
      blogListingTemplate
    };
  });
};
const fetchBlogActiveThemeTemplates = () => {
  return fetchPrimaryBrandKit().then(brandKit => {
    return brandKit.activeThemePath;
  }).then(activeThemePath => {
    if (activeThemePath) {
      return fetchBlogTemplates(activeThemePath).catch(() => fetchBlogTemplates(ELEVATE_THEME_PATH)); // default to active theme if cant find required templates in active theme
    }
    return fetchBlogTemplates(ELEVATE_THEME_PATH);
  }).catch(() => {
    return fetchBlogTemplates(ELEVATE_THEME_PATH);
  });
};
const fetchPrimaryBlogDomain = () => {
  return domainsClient.fetchDomainsForBlogSettings({}).then(response => {
    const blogDomains = response.objects;
    const primaryBlogDomain = getPrimaryDomainForBlog(blogDomains);
    return primaryBlogDomain ? primaryBlogDomain.domain : undefined;
  });
};
export const fetchBlogQuickCreateRequiredData = () => {
  return Promise.all([fetchPrimaryBlogDomain(), userInfo(), fetchBlogActiveThemeTemplates()]).then(([domain, auth, templates]) => {
    const defaultLanguage = auth && auth.user.lang_enabled ? auth.user.locale : 'en';
    const scopes = auth && auth.user.scopes || [];
    const blogTemplate = templates.blogTemplate.path;
    const blogListingTemplate = templates.blogListingTemplate.path;
    return {
      domain,
      blogTemplate,
      blogListingTemplate,
      defaultLanguage,
      scopes
    };
  });
};
export const useBlogQuickCreateRequiredData = () => {
  const [requiredData, setRequiredData] = useState({
    domain: undefined,
    blogTemplate: undefined,
    blogListingTemplate: undefined,
    defaultLanguage: undefined,
    scopes: []
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    fetchBlogQuickCreateRequiredData() // this request is cached so we won't be calling multiple times as the main app will probably call this on startup
    .then(data => {
      setRequiredData(data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, []);
  return {
    requiredData,
    isLoading
  };
};